import useJwt from '@/auth/jwt/useJwt'
import VueJwtDecode from 'vue-jwt-decode'


export const isUserLoggedIn = () => {
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  if (token == null || token == undefined || token == null) {
    return false
  }
  let isTokenNotExp = Date.now() < 1000 * VueJwtDecode.decode(token).exp
  return localStorage.getItem('userData') && isTokenNotExp
}

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'ROLE_SUPERVISOR') return { name: 'supervisor-dashboard' }
  if (userRole === 'ROLE_ADMIN') return { name: 'admin-dashboard' }
  if (userRole === "ROLE_SUPPLIER") return { name: 'supplier-dashboard' }
  if (userRole === 'ROLE_CUSTOMER' || userRole === 'ROLE_EDITOR') return { name: 'new-order' }
  return { name: 'auth-login' }
}

export const gerRole = () => {
  return JSON.parse(localStorage.getItem('userData')).role;
}

export const isAdministrator = () => {
  return JSON.parse(localStorage.getItem('userData')).role == "ROLE_ADMIN";
}

export const isSupervisor = () => {
  return JSON.parse(localStorage.getItem('userData')).role == "ROLE_SUPERVISOR";
}

export const isPharmacy = () => {
  return ["ROLE_CUSTOMER", "ROLE_EDITOR"].includes(JSON.parse(localStorage.getItem('userData')).role);
}
